<template>
  <div id="Module">
    <!-- ***** App Screenshots Area Start ***** -->
    <section id="Module">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="section-heading text-center">
              <h2>Module</h2>
              <div class="line-title-center"></div>
              <p style="font-size: 16px;">
                biosoft มี ระบบงาน (Module) ในการรองรับการบริหารทรัพยากรบุคคลได้อย่างครบถ้วน 7 Module
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <carousel
              :autoplay="true"
              :responsive="{
                0: { items: 1, nav: false },
                600: { items: 1, nav: true },
              }"
            >
              <v-lazy-image
                v-for="(img, index) in images"
                :src="img"
                :key="index"
              />
            </carousel>
          </div>
        </div>
        <div class="container" style="margin-top: 30px;">
        <div class="row">
          <div class="col-12 col-lg-6 col-md-6">
            <div class="how-it-single-module">
              <h4 style="color: #636d75;"><i class="icon-people"></i> Employee</h4>
              <div class="line-title-center" style="margin-left: inherit;"></div>
              <p>
                สามารถกำหนดรหัสพนักงานและรหัสบัตรรูดหรือสแกนลายนิ้ว ได้เหมือนหรือต่างกันก็ได้ สามารถคำนวณ ระยะเวลาวันผ่านการทดลองงานได้ ทั้ง เป็น วัน เดือน และปี และคำนวณวันที่ผ่าน การทดลองงานได้อัตโนมัติ
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-6 col-md-6">
            <div class="how-it-single-module">
              <h4 style="color: #636d75;"><i class="icon-clock"></i> Time</h4>
              <div class="line-title-center" style="margin-left: inherit;"></div>
              <p>
                สามารถดึงข้อมูลประวัติพนักงานมาใช้ในส่วนของ Time Attendance ได้อัตโนมัติและกำหนดอัตราการคิด โอที สาย ได้แตกต่างกันและหลายอัตราในกะการทำงาน
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-6 col-md-6">
            <div class="how-it-single-module">
              <h4 style="color: #636d75;"><i class="icon-wallet"></i> Payroll</h4>
              <div class="line-title-center" style="margin-left: inherit;"></div>
              <p>
                สามารถคำนวณเงินเดือนพนักงานได้ตามฝ่าย ส่วน แผนก ตำแหน่ง ประเภทพนักงาน และทุกคน สามารถกำหนดช่วงการคำนวณเงินเดือนได้ สามารถกำหนดอัตราการคิดกองทุนสำรองเลี้ยงชีพ โดยคิดตามอายุงานได้อัตโนมัติ
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-6 col-md-6">
            <div class="how-it-single-module">
              <h4 style="color: #636d75;"><i class="icon-graduation"></i> Training</h4>
              <div class="line-title-center" style="margin-left: inherit;"></div>
              <p>
                กำหนดรายละเอียดการฝึกอบรมได้ไม่จำกัดหลักสูตร สามารถกำหนดหัวข้อในการประเมินพนักงานฝึกอบรบได้ทั้ง คะแนน สามารถรายงานรวมทั้งบริษัทได้ว่าใช้งบประมาณในการอบรมไปเท่าไหร่
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-6 col-md-6">
            <div class="how-it-single-module">
              <h4 style="color: #636d75;"><i class="icon-present"></i> Welfare</h4>
              <div class="line-title-center" style="margin-left: inherit;"></div>
              <p>
                กำหนดรายละเอียดสวัสดิการได้ไม่จำกัด เช่น อุปกรณ์, ค่ารักษาพยาบาล, ชุดฟอร์ม กำหนดรอบการคิดสวัสดิการได้เองโดยไม่จำเป็นต้องตรงกับรอบการคิดเงินเดือนก็ได้
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-6 col-md-6">
            <div class="how-it-single-module">
              <h4 style="color: #636d75;"><i class="icon-book-open"></i> Recruitment</h4>
              <div class="line-title-center" style="margin-left: inherit;"></div>
              <p>
                สามารถเก็บประวัติผู้สมัครงานได้ไม่จำกัดสามารถเก็บหลักฐานการประกอบการสมัครงานได้สามารถบันทึกผลการสอบสัมภาษณ์ของพนักงานได้
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-6 col-md-6">
            <div class="how-it-single-module">
              <h4 style="color: #636d75;"><i class="icon-speedometer"></i> Evaluate</h4>
              <div class="line-title-center" style="margin-left: inherit;"></div>
              <p>
                สามารถกำหนดหัวข้อการประเมินผลการทำงานได้เองสามารถประเมินผลการทำงานของพนักงานได้ไม่จำกัด สามารถกำหนดคะแนนการประเมินผลการทำงานของพนักงานได้
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-6 col-md-6">
            <div class="how-it-single-module">
              <h4 style="color: #636d75;"><i class="icon-docs"></i> Reports</h4>
              <div class="line-title-center" style="margin-left: inherit;"></div>
              <p>
                เรามีรายงานที่หลากหลายและครบถ้วนให้ท่านดู ทั้งหมด 100+ กว่านรายงาน ไม่ว่าจะเป็นรายงานราชการ หรือ รายงานสรุปเวลา โอที การจ่ายเงิน เป็นต้น
              </p>
            </div>
          </div>
        </div>
      </div>
      </div>
    </section>
  </div>
</template>

<script>
const carousel = () => import("vue-owl-carousel");
const VLazyImage = () => import("v-lazy-image");
export default {
  name: "Module",
  components: {
    carousel,
    VLazyImage,
  },
  data: function() {
    return {
      images: [
        "assets/images/module/Biosoft_Employee.jpg",
        "assets/images/module/Biosoft_Time.jpg",
        "assets/images/module/Biosoft_Payroll.jpg",
        "assets/images/module/Biosoft_Training.jpg",
        "assets/images/module/Biosoft_Welfare.jpg",
        "assets/images/module/Biosoft_Evaluate.jpg",
        "assets/images/module/Biosoft_Recruitment.jpg",
        // "https://img-website-biosoft.s3-ap-southeast-1.amazonaws.com/Module/Biosoft_Time.jpg",
        // "https://img-website-biosoft.s3-ap-southeast-1.amazonaws.com/Module/Biosoft_Payroll.jpg",
        // "https://img-website-biosoft.s3-ap-southeast-1.amazonaws.com/Module/Biosoft_Training.jpg",
        // "https://img-website-biosoft.s3-ap-southeast-1.amazonaws.com/Module/Biosoft_Welfare.jpg",
        // "https://img-website-biosoft.s3-ap-southeast-1.amazonaws.com/Module/Biosoft_Evaluate.jpg",
        // "https://img-website-biosoft.s3-ap-southeast-1.amazonaws.com/Module/Biosoft_Recruitment.jpg",
      ],
    };
  },
};
</script>

<style scoped>
.v-lazy-image {
  filter: blur(10px);
  transition: filter 3s;
}
.v-lazy-image-loaded {
  filter: blur(0);
}
.how-it-single-module {
    padding: 40px;
    margin-bottom: 30px;
    -webkit-transition-duration: 800ms;
    -o-transition-duration: 800ms;
    transition-duration: 800ms;
    -webkit-box-shadow: 0 10px 90px rgba(0, 0, 0, 0.08);
            box-shadow: 0 10px 90px rgba(0, 0, 0, 0.08);
}
</style>
